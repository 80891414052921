import React, { useState } from "react";

/// React router dom
import { Link } from "react-router-dom";

const NavHader = () => {
   const [toggle, setToggle] = useState(false);
   return (
      <div className="nav-header">
         <Link to="/" className="brand-logo">
            {/* <img className="logo-abbr" src={"https://doctor.swayamhealth.in/swayam-for-Swapnil.png"} alt="" /> */}
            {toggle ? (
               // Change when collapsed
               <img
                  width="60%"
                  src="https://doctor.bonumhealth.co/bonum-icon.png" alt="" />
            ) : (
               // Change when not collapsed
               <img
                  width="80%"
                  src="https://doctor.bonumhealth.co/bonum-for-Swapnil.png" alt="" />
            )}
            {/* <img className="brand-title" src={"https://doctor.swayamhealth.in/swayam-for-Swapnil.png"} alt="" /> */}
         </Link>

         <div className="nav-control" onClick={() => setToggle(!toggle)}>
            <div className={`hamburger ${toggle ? "is-active" : ""}`}>
               <span className="line"></span>
               <span className="line"></span>
               <span className="line"></span>
            </div>
         </div>

      </div>
   );
};

export default NavHader;
