import CryptoJS from "crypto-js";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

const Header = ({ onProfile, title, }) => {
   const [userToken, setUserToken] = useState({})

   useEffect(() => {
      const item = localStorage.getItem("orgDetails")
      var bytes = CryptoJS.AES.decrypt(JSON.parse(item), 'SWAYAMORGLOGIN');
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setUserToken(decryptedData)
   }, [])

   var path = window.location.pathname.split("/");
   var name = path[path.length - 1].split("-");
   var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
   var finalName = filterName.includes("app")
      ? filterName.filter((f) => f !== "app")
      : filterName.includes("ui")
         ? filterName.filter((f) => f !== "ui")
         : filterName.includes("uc")
            ? filterName.filter((f) => f !== "uc")
            : filterName.includes("basic")
               ? filterName.filter((f) => f !== "basic")
               : filterName.includes("form")
                  ? filterName.filter((f) => f !== "form")
                  : filterName.includes("table")
                     ? filterName.filter((f) => f !== "table")
                     : filterName.includes("page")
                        ? filterName.filter((f) => f !== "page")
                        : filterName.includes("email")
                           ? filterName.filter((f) => f !== "email")
                           : filterName.includes("ecom")
                              ? filterName.filter((f) => f !== "ecom")
                              : filterName.includes("chart")
                                 ? filterName.filter((f) => f !== "chart")
                                 : filterName.includes("editor")
                                    ? filterName.filter((f) => f !== "editor")
                                    : filterName;
   return (
      <div className="header" >
         <div className="header-content">
            <nav className="navbar navbar-expand">
               <div className="collapse navbar-collapse justify-content-between">
                  <div className="header-left">
                     <div
                        className="dashboard_bar"
                        style={{ textTransform: "capitalize", }}
                     >
                        {title}
                     </div>
                  </div>
                  <ul className="navbar-nav header-right">

                     <li
                        className="nav-item dropdown flex justify-content-end"
                        onClick={() => onProfile()}
                     >
                        <img src={userToken.logo} width={110} alt="UAE" />
                     </li>
                  </ul>
               </div>
            </nav>
         </div>
      </div>
   );
};

export default Header;
